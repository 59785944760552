.grid-results-1 {
    grid-template-columns: repeat(1, minmax(80px, 240px));
}

.grid-results-2 {
    grid-template-columns: repeat(2, minmax(80px, 240px));
}

.grid-results-3 {
    grid-template-columns: repeat(3, minmax(80px, 240px));
}

.grid-results-4 {
    grid-template-columns: repeat(4, minmax(80px, 240px));
}

.grid-results-5 {
    grid-template-columns: repeat(5, minmax(80px, 240px));
}

.grid-results-6 {
    grid-template-columns: repeat(6, minmax(80px, 240px));
}

.grid-results-7 {
    grid-template-columns: repeat(7, minmax(80px, 240px));
}

.grid-results-8 {
    grid-template-columns: repeat(8, minmax(80px, 240px));
}

.grid-results-9 {
    grid-template-columns: repeat(9, minmax(80px, 240px));
}

.grid-results-10 {
    grid-template-columns: repeat(10, minmax(80px, 240px));
}

.grid-results-11 {
    grid-template-columns: repeat(11, minmax(80px, 240px));
}

.grid-results-12 {
    grid-template-columns: repeat(12, minmax(80px, 240px));
}

.grid-results-13 {
    grid-template-columns: repeat(13, minmax(80px, 240px));
}

.grid-results-14 {
    grid-template-columns: repeat(14, minmax(80px, 240px));
}

.grid-results-15 {
    grid-template-columns: repeat(15, minmax(80px, 240px));
}

.grid-results-16 {
    grid-template-columns: repeat(16, minmax(80px, 240px));
}

.grid-results-17 {
    grid-template-columns: repeat(17, minmax(80px, 240px));
}

.grid-results-18 {
    grid-template-columns: repeat(18, minmax(80px, 240px));
}

.grid-results-19 {
    grid-template-columns: repeat(19, minmax(80px, 240px));
}

.grid-results-20 {
    grid-template-columns: repeat(20, minmax(80px, 240px));
}
