.c-cart-wrapper {
    display: flex;
    max-height: 0;
    opacity: 0;
    .c-cart-container {
        transition-delay: 200ms;
        transition: transform 1s ease;
        transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); //transform-style: preserve-3d;
    }

    &.active {
        max-height: 100%;
        opacity: 1;

        .c-cart-container {
            transition-delay: 200ms;
            transition: transform 1s ease;
            transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); //transform-style: preserve-3d;
        }
    }
}
