.w-112,
.xl\:w-112 {
    width: 28rem;
}

.ios-prompt {
    background-color: #fcfcfc;
    border: 1px solid #666;
    display: none;
    padding: 0.8rem 1rem 0 0.5rem;
    text-decoration: none;
    font-size: 16px;
    color: #555;

    position: absolute;
    margin: 0 auto 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 0;
}

.filter-black {
    filter: grayscale(1) contrast(200);
}
#SRLLightbox {
    z-index: 100;
}

.filter-white {
    filter: contrast(0.5) grayscale(1) brightness(10) saturate(0.5);
}

html {
    &.modal-open {
        overflow-y: hidden;
        height: 100%;
    }
}

.location-search-input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
}

.App {
    /*background-color: skyblue;*/
}
.form-control {
    box-sizing: border-box;
}

.strike {
    text-decoration: line-through;
}

.ReactModalPortal .dzu-dropzone {
    min-width: 50vw;
    min-height: 70vh;
    overflow: auto;
}

.FormInput {
    border-bottom: 1px solid #dfe3e8;
    display: flex;
    align-items: center;
    padding: 4px 10px 2px;
    margin-bottom: 5px;
    label {
        color: #6b6b6b;
        font-weight: 400;
        font-size: 15px;
        white-space: nowrap;
    }
    .InputWrap {
        position: relative;
        flex-grow: 1;
        input {
            border: none;
            border-radius: 0;
            width: 100%;
            padding: 10px;
            font-family: "Open Sans", sans-serif;
            font-size: 15px;
            &:focus {
                outline: none;
            }
        }
    }
    button {
        img {
            width: 18px;
        }
    }
}

.FormInput .InputWrap > div {
    margin: 0;
}

/** pouzivam znovu button */
$shopgreen: #82c855;
@mixin button {
    font-size: 0.75rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    border-radius: 4px;
    border: none;
    line-height: 18px;
    padding: 3px 10px 2px 10px;
    -webkit-appearance: none;
    cursor: pointer;
}
.GreenBtn {
    @include button;
    font-weight: 900;
    background: $shopgreen;
    border-radius: 0;
    color: #fff;
    height: 66px;
    padding: 10px 30px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.Formats {
    padding: 3px 11px;
    table {
        font-size: 0.9rem;
        width: 100%;
        border-spacing: 0;
        @media (max-width: 580px) {
            font-size: 0.82rem;
        }
        tr {
            /*koment*/
            td {
                padding: 9px 0;
                border-bottom: 1px solid #eee;
                &:first-child {
                    font-weight: bold;
                }
                &:last-child {
                    text-align: right;
                }
                .PlusOne {
                    font-weight: bold;
                    font-size: 0.95rem;
                    color: #fff;
                    background: $shopgreen;
                    border-radius: 4px;
                    width: 18px;
                    height: 13px;
                    padding: 0;
                    line-height: 1rem;
                    padding-bottom: 5px;
                    padding-top: 0px;
                    box-sizing: content-box;
                    text-decoration: none;
                    margin-right: 8px;
                    position: relative;
                    transition: 300ms;
                    &:before {
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 20px;
                        border-radius: 6px;
                        border: 1px solid $shopgreen;
                        left: -2px;
                        top: -2px;
                    }
                    &:hover {
                        transform: scale(1.1);
                    }
                }
                .MinusOne {
                    font-weight: bold;
                    font-size: 0.95rem;
                    color: #fff;
                    background: #808080;
                    border-radius: 4px;
                    width: 18px;
                    height: 13px;
                    padding: 0;
                    line-height: 1rem;
                    padding-bottom: 5px;
                    padding-top: 0px;
                    box-sizing: content-box;
                    text-decoration: none;
                    transition: 300ms;
                    &:hover {
                        background: #d01a1a;
                    }
                }
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

// tailwind ho neobsahuje, ked tak rozsirit ako utilities
.overflow-anywhere {
    overflow-wrap: anywhere;
}