.jumbo {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: "Kostic roc grotesk condensed", sans-serif;
    font-size: 13vw;
    line-height: 0.7;
    font-weight: 500;
}

html {
    height: 100%;
    scroll-behavior: smooth;
    padding: 0 !important;
    overflow: unset !important;
}

body {
    background-color: #f2f2f2;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-family: Inter, sans-serif;
    color: #333;
    /*font-size: 0.9rem;*/
    /*line-height: 1.6em;*/
    font-weight: 400;
}
.left-100 {
    left: 100%;
}
.h-5vw {
    height: 5vw;
}
.h-fullplus1 {
    height: calc(100% + 2px);
    margin-top: -1px;
}
.h-10vw {
    height: 10vw;
}
.h-15 {
    height: 3.5rem;
}
.h-86 {
    height: 86%;
}
.h-90 {
    height: 90%;
}
.w-86 {
    width: 86%;
}
.w-90 {
    width: 90%;
}
.w-5vw {
    width: 5vw;
}
.w-10vw {
    width: 10vw;
}
.w-80vw {
    width: 80vw;
}
.w-95vw {
    width: 95vw;
}
.w-fullpx {
    width: 100px;
}
.w-120px {
    width: 120px;
}
.w-200px {
    width: 200px;
}
.top-5vw {
    top: 5vw;
}
.pt-4\.5vh {
    padding-top: 4.5vh;
}
.pb-4\.5vh {
    padding-bottom: 4.5vh;
}
.pt-5vw {
    padding-top: 5vw;
}
.pl-5vw {
    padding-left: 5vw;
}
.left-1vw {
    left: 1vw;
}
.right-1vw {
    right: 1vw;
}
.bottom-1\.5vh {
    bottom: 1.5vh;
}
.top-1\.5vh {
    top: 1.5vh;
}
.left-5vw {
    left: 5vw;
}
.left {
    left: 2.5vw;
}
.basis-50 {
    flex-basis: 50%;
}
.basis-66 {
    flex-basis: 66%;
}
.basis-65 {
    flex-basis: 65%;
}
.basis-33 {
    flex-basis: 33%;
}
.basis-34 {
    flex-basis: 34%;
}
.basis-35 {
    flex-basis: 35%;
}
.min-w-10 {
    min-width: 2.5rem;
}
.min-w-20 {
    min-width: 5rem;
}
.min-w-30 {
    min-width: 7.5rem;
}
.min-h-200px {
    min-height: 200px;
}
.min-h-fullvh {
    min-height: 100vh;
}
.max-w-1280 {
    max-width: 1280px;
}
.max-w-50vw {
    max-width: 50vw;
}
.max-w-80w {
    max-width: 80vw;
}

.min-w-400 {
    min-width: 400px;
}
.max-w-400 {
    min-width: 400px;
}
.max-h-full {
    max-height: 32rem;
}
.max-h-76 {
    max-height: 19rem;
}
.max-h-full {
    max-height: 80vh;
}

.ml-10vw {
    margin-left: 10vw;
}
.mr-10vw {
    margin-right: 10vw;
}
.w-220 {
    width: 220px;
}
.w-1px {
    width: 1px;
}
.w-50 {
    width: 50%;
}
.z-1 {
    z-index: 1;
}
.z-2 {
    z-index: 2;
}
.z-3 {
    z-index: 3;
}
.skew-y-20 {
    --tw-skew-y: 20deg;
}
.font-sans-serif-kc {
    font-family: "Kostic roc grotesk condensed", sans-serif;
}
.font-sans-serif-k {
    font-family: "Kostic roc grotesk", sans-serif;
}
.font-sans-serif-g {
    font-family: Gopher, sans-serif;
}
.font-sans-serif-m {
    font-family: "Monumentextended", sans-serif;
}
.line-0\.9 {
    line-height: 0.9;
}
.bg-strip-orange {
    background-color: #f6956d;
    background-image: -webkit-gradient(linear, left top, right top, from(#f5bba8), to(#f05841));
    background-image: linear-gradient(90deg, #f5bba8, #f05841);
}

.bg-strip-blue {
    background-color: #14213d;
    background-image: -webkit-gradient(linear, left top, right top, from(#2c4780), to(#14213d));
    background-image: linear-gradient(90deg, #2c4780, #14213d);
}
.shadow-inset {
    box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.3), inset 0 10px 10px 0 rgba(0, 0, 0, 0.3);
}
.shadow-dark {
    box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.shadow-white {
    box-shadow: 0 -8px 10px 0 #fff;
}
.shadow-dash {
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.1);
}

.text-4\.5xl {
    font-size: 2.6rem;
    line-height: 0.9;
}
.text-blue-03 {
    color: #030826;
}
.text-blue-43 {
    color: #434b9e;
}
.text-red-f0 {
    color: #f05841;
}
.text-gray-c7 {
    color: #c7c6c6;
}
.text-gray-9c {
    color: #9c9b9b;
}
.bg-gradient-blue {
    background-image: linear-gradient(315deg, #434b9e, #14213d);
}
.spacing-px {
    letter-spacing: -0.5px;
}
.rounded-tl-4xl {
    border-top-left-radius: 2rem;
}
.rounded-4xl {
    border-radius: 2rem;
}
.bg-whitesmoke {
    background-color: #f6f7fb;
}
.bg-whitewash {
    background-color: #f2f2f2;
}
.softlight-gradient {
    background-image: linear-gradient(315deg, #000, #fff);
}
.softlight {
    mix-blend-mode: soft-light;
}
.bg-blue-dark {
    background-color: #061058;
}
.bg-blue-custom {
    background-color: #3f5fff;
}
.bg-blue-form {
    background-color: rgba(63, 95, 255, 0.1);
}
.drop-shadow {
    text-shadow: -21px 21px 40px rgba(63, 95, 255, 0.2);
}
.bg-blue-custom-shadow {
    box-shadow: -21px 21px 40px 0 rgba(63, 95, 255, 0.2);
}
.NavBarItem img {
    filter: grayscale(1);
}
.NavBarItem:hover img,
.NavBarItem .w--current img {
    filter: grayscale(0);
}
.NavBarItem .absolute {
    max-width: 0;
    transition: max-width 0.4s ease-in-out;
}
.NavBarItem:hover .absolute {
    max-width: 300px;
}

.form-divider-line {
    width: 23%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
}
button:focus {
    outline: 0;
}

.ranking-absolute-img {
    position: absolute;
    left: 0%;
    transform: translateX(-40%);
    bottom: -5%;
    z-index: 2;
    width: 70%;
    height: 70%;
    background-image: url("assets/img/tomkart.png");
    background-position: 0% 100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.will-change {
    will-change: width, height;
}
.scrollbar::-webkit-scrollbar,
.overflow-y-auto::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
.scrollbar::-webkit-scrollbar-thumb,
.overflow-y-auto::-webkit-scrollbar-thumb {
    background: #000;
}

.TooltipArrow {
    width: 0;
    height: 0;
    transform: translate(50%, 32px);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 32px solid #1d3d9b;
    position: absolute;
    bottom: 0;
    right: 50%;
}

/* slide in animations */
/* slideinLeft */
.slideinLeft-appear {
    opacity: 0;
    will-change: transform;
}
.slideinLeft-appear-active {
    opacity: 0;
    will-change: transform;
    transform: translateX(-100%);
}
.slideinLeft-enter {
    opacity: 0;
    will-change: transform;
    transform: translateX(-100%);
}
.slideinLeft-enter-done {
    opacity: 1;
    will-change: transform;
    transform: translateX(0%);
}
.slideinLeft-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.slideinLeft-exit {
    opacity: 1;
    will-change: transform;
    transform: translateX(0%);
}

.slideinLeft-exit-active {
    opacity: 1;
    will-change: transform;
    transition: all 500ms ease-in;
}

/*slideinRight*/
.slideinRight-appear {
    opacity: 0;
    will-change: transform;
}
.slideinRight-appear-active {
    opacity: 0;
    will-change: transform;
    transform: translateX(50%);
}
.slideinRight-enter {
    opacity: 0;
    will-change: transform;
    transform: translateX(50%);
}
.slideinRight-enter-done {
    opacity: 1;
    will-change: transform;
    transform: translateX(0%);
}
.slideinRight-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.slideinRight-exit {
    opacity: 1;
    will-change: transform;
    transform: translateX(0%);
}

.slideinRight-exit-active {
    opacity: 1;
    will-change: transform;
    transition: all 500ms ease-in;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animation-fadein {
    animation: fadein 1s linear 1 1s;
}

body .react-datepicker__input-container {
    display: flex;
}


.clip-text {
    -webkit-background-clip: text;
}

.text-fill-transparent {
    -webkit-text-fill-color: transparent;
}
